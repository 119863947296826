<template>
    <div>
        <a-form :form="form" @submit="handleSubmit">
            <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ paddingTop: 0, paddingBottom: '40px' }">
                <!-- <template #title>
              <h5 class="mb-0">Mention details</h5>
            </template> -->
                <a-row type="flex" :gutter="24">
                    <a-col :span="12" :lg="12" class="mb-12">
                        <h5 style="font-size: 20px; padding-top: 16px; padding-bottom: 16px;">Mention details</h5>
                        <a-row :gutter="[24]">
                            <a-col :span="12">
                                <a-form-item class="mb-10" label="Title" :colon="false">
                                    <a-input v-model="mention.title" placeholder="eg. Good Mention" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item class="mb-10" label="Sub-title" :colon="false">
                                    <a-input v-model="mention.sub_title" placeholder="eg. Gaudí's iconic creation" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12" v-if="!isTypeSeparator">
                                <a-form-item class="mb-10" label="Place" :colon="false">
                                    <a-select v-model="mention.place_id" style="width: 100%" placeholder="Place"
                                        @change="handlePlaceChange">
                                        <a-select-option v-for="place in toChoosePlaces" :key="place.id"
                                            :value="place.id" selected>
                                            {{ place.title }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col v-if="isEdit" :span="12">
                                <a-form-item class="mb-10" label="Status" :colon="false">
                                    <a-switch v-model="mention.is_active" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="24">
                                <a-form-item style="margin-top: 50px">
                                    <a-upload :action="`${apiUrl}/upload/file`" list-type="picture-card"
                                        :file-list="fileList" @preview="handlePreview" @change="handleChange">
                                        <div v-if="fileList.length < 8">
                                            <a-icon type="plus" />
                                            <div class="ant-upload-text">Upload</div>
                                        </div>
                                    </a-upload>
                                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                                        <img alt="example" style="width: 100%" :src="previewImage" />
                                    </a-modal>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="12" :lg="12" class="mb-12">
                        <a-row :gutter="[24]"
                            style="padding-top: 16px; padding-bottom: 16px; padding-right: 15px; display: flex; justify-content: right;">
                            <a-button-group v-if="!isEdit">
                                <!-- <a-icon type="left" /> -->
                                <a-button type="primary" :disabled="!isTypeSeparator"
                                    @click="handleTypeChange">Mention</a-button>
                                <!-- <a-icon type="right" />  -->
                                <a-button type="primary" :disabled="isTypeSeparator"
                                    @click="handleTypeChange">Separator</a-button>
                            </a-button-group>
                            <!-- to correct the line to to title and sub-title inputs -->
                            <h1 v-else style="margin-top: 15px;"></h1>
                        </a-row>
                        <a-row :gutter="[24]">
                            <a-col :span="24" v-if="!isTypeSeparator">
                                <a-form-item class="mb-10" label="Address" :colon="false">
                                    <!-- :disabled="true"  -->
                                    <a-input v-model="mention.address" placeholder="Will be generated automatically" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="24">
                                <a-form-item class="mb-10" label="Description" :colon="false">
                                    <quill-editor v-model="quillHtmlConvContent" :options="quillOptions"
                                        style="height: 200px" @change="handleQuillChange" />
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </a-card>
            <a-button type="primary" html-type="submit" style="margin-top: 20px;"> Submit </a-button>
        </a-form>
    </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import router from "../../router/index.js";
import placeAPI from "../../api/place.js";
import mentionAPI from "../../api/mention.js";
import { quillEditor } from "vue-quill-editor";
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';


export default {
    components: {
        quillEditor,
    },
    data() {
        return {
            quillHtmlConvContent: null,
            apiUrl: process.env.VUE_APP_BASE_URL,
            mention: {
                id: null,
                title: "",
                sub_title: "",
                description: "",
                address: "",
                longitude: 0,
                latitude: 0,
                place_id: "",
                media: [],
                is_active: false,
                type: "mention",
            },
            isTypeSeparator: false,
            mentionEditData: null,
            isEdit: false,
            previewVisible: false,
            fileList: [],
            form: this.$form.createForm(this, { name: "editMention" }),
            previewImage: null,
            quillContent: {},
            toChoosePlaces: [],
            quillOptions: {
                modules: {
                    toolbar: [
                        [{ header: [1, 2, 3, false] }],
                        ["bold", "italic", "underline"],
                        [{ list: "ordered" }, { list: "bullet" }, "link"],
                        ["clean"],
                    ],
                },
            },
        };
    },
    methods: {
        handleQuillChange(content) {
            this.quillContent = content.quill.editor.delta;
        },
        async getMentionById(id) {
            return mentionAPI.getMention(id);
        },
        async getPlaces() {
            return placeAPI.getPlaces();
        },
        handleCancel() {
            this.previewVisible = false;
        },
        handlePlaceChange(value) {
            const selectPlace = this.toChoosePlaces.find(
                (place) => place.id === value
            );
            if (selectPlace) {
                this.mention.address = selectPlace.address;
                this.mention.latitude = selectPlace.latitude;
                this.mention.longitude = selectPlace.longitude;
            }
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await this.getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        handleChange({ fileList }) {
            this.fileList = fileList;
        },
        handleTypeChange() {
            this.isTypeSeparator = !this.isTypeSeparator;
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err) => {
                if (!err) {
                    for (let file of this.fileList) {
                        this.mention.media.push(file.response.data.id);
                    }
                    if (this.isTypeSeparator) {
                        this.mention.place_id = "";
                        this.mention.address = "";
                        this.mention.type = "separator";
                    }
                    this.mention.description = this.quillContent;
                    if (!this.isEdit) {
                        mentionAPI.createMention(this.mention).then((responseData) => {
                            if (responseData.status == 200) {
                                router.push("/mentions/mentions-list");
                            }
                        }).catch((err) => {
                            this.mention.media = [];
                        });
                    } else {
                        mentionAPI.updateMention(this.mention).then((responseData) => {
                            if (responseData.status == 200) {
                                router.push("/mentions/mentions-list");
                            }
                        }).catch((err) => {
                            this.mention.media = [];
                        });
                    }
                }
            });
        },
    },
    async mounted() {
        if (this.$route.params.id !== undefined) {
            this.mentionEditData = await this.getMentionById(this.$route.params.id);
            this.isEdit = true;
            this.mention.id = this.mentionEditData.id;
            this.mention.sub_title = this.mentionEditData.sub_title;
            this.mention.title = this.mentionEditData.title;
            this.mention.latitude = this.mentionEditData.latitude;
            this.mention.longitude = this.mentionEditData.longitude;
            this.mention.address = this.mentionEditData.address;
            if (this.mentionEditData.place != null) {
                this.mention.place_id = this.mentionEditData.place.id;
            }
            this.mention.is_active = this.mentionEditData.is_active;
            if (this.mentionEditData.description != {} && this.mentionEditData.description != null) {
                this.quillContent = this.mentionEditData.description;
                var deltaOps = this.mentionEditData.description.ops;
                var converter = new QuillDeltaToHtmlConverter(deltaOps, {});
                this.quillHtmlConvContent = converter.convert();
            }

            if (this.mentionEditData.type === "separator") {
                this.isTypeSeparator = true;
            }
            if (this.mentionEditData.media != null) {
                for (let i = 0; i < this.mentionEditData.media.length; i++) {
                    let media = this.mentionEditData.media[i];
                    let name = media.url.split("+")[media.url.split("+").length - 1];
                    this.fileList.push({
                        uid: media.id,
                        response: {
                            data: {
                                id: media.id,
                            },
                        },
                        status: "done",
                        name: name,
                        url: "https://" + media.url,
                    });
                }
            }
        }
        const dataPlaces = await this.getPlaces();
        this.toChoosePlaces = dataPlaces.places;
    },
};
</script>

<style lang="scss" scoped>
 .product-img {
     border-radius: 12px;
 }
</style>
